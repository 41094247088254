import { localeWithCountryCode } from '../utils/localeUtils'

export function definePublisher() {

  const content = useContentStore().content;

  return defineOrganization({
    name: useRuntimeConfig()?.public['nuxt-schema-org']?.identity?.name,
    url: `https://${useRuntimeConfig()?.public.SITE_URL}`,
    description: useTranslationStore()?.getTranslation('meta.description'),
    logo: defineImage({
      url: `https://${useRuntimeConfig()?.public?.SITE_URL}/logo.png`,
      inLanguage: localeWithCountryCode(content?.locale, true) || ''
    }),
  })
}
