<script setup lang="ts">
  import { computed, defineProps } from 'vue'

  // Define the prop type
  const props = defineProps<{
    type: 'Market' | 'Primary' | 'Secondary'
    disabled?: boolean
  }>()

  // Define computed styles based on the prop value
  const buttonClass = computed(() => {
    switch (props.type) {
      case 'Market':
        return 'bg-brand-primary mkt-subscribe-trigger rounded-sm  border-small hover:border-brand-primary border-brand-tertiary font-button text-brand-tertiary p-3 lg:p-4 hover:bg-brand-tertiary hover:text-brand-primary'
      case 'Primary':
        return 'bg-button-secondary text-button-primary text-white p-4 border-small border-tone-primary'
    }
  })

  // Define computed styles for the --icon-color CSS variable
  const buttonStyle = computed(() => {
    switch (props.type) {
      case 'Market':
        return { '--icon-color': 'var(--secondary-marketing)' } // Example color for Market
      case 'Primary':
        return { '--icon-color': 'var(--button-primary)' } // Example color for Primary
      case 'Secondary':
        return { '--icon-color': 'var(--button-secondary)' } // Example color for Secondary
      default:
        return {}
    }
  })
</script>

<template>
  <button
    :class="[
      'grid w-full grid-cols-[var(--spacing-5)_1fr_var(--spacing-5)]',
      buttonClass,
    ]"
    :disabled="props.disabled"
    :style="buttonStyle"
  >
    <slot />
  </button>
</template>
